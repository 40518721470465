import { template as template_1fc37f9efcca4d3c8c0db9f9260abef5 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_1fc37f9efcca4d3c8c0db9f9260abef5(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;

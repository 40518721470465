import { template as template_f2e378844e0d4379ba0870a5854865b9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_f2e378844e0d4379ba0870a5854865b9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;

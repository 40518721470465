import { template as template_3fc6692e91cd4bee8188fa8e301479dd } from "@ember/template-compiler";
const WelcomeHeader = template_3fc6692e91cd4bee8188fa8e301479dd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
